import _ from "lodash";
import { usePrinter } from "../../store/schedule/usePrinter";
import { gaida } from "../api";
import { formatResponse } from "../formatResponses/global";
import { clearNumber } from "../functions";
import { mock } from "../../mock";
import { queryClient } from "../react-query";

const printerActions = usePrinter.getState().actions

export default class RecommendationsBuilder {
  constructor(props) {
    this._cpf = props.cpf
    this.getRecommendations()
  }


  async getRecommendations() {
    if (process.env.NODE_ENV === 'development') {
      const data = await mock.gaida.get.suggestion_customer();

      const formattedRecommendations = formatResponse.customerRecommendations(data.suggestions);
      printerActions.setCustomerRecommendations(formattedRecommendations)

      return
    }

    const { data } = await queryClient.fetchQuery({
      queryKey: ['gaida-recommendation'],
      queryFn: async () => gaida.get(`/suggestion/customer/${clearNumber(this._cpf)}`),
      retry: false,
      staleTime: Infinity,
    })

    const formattedRecommendations = formatResponse.customerRecommendations(data.suggestions);
    printerActions.setCustomerRecommendations(formattedRecommendations)

  };
}
