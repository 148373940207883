import { usePet } from "../../../../../../../store/global/usePet";
import { usePrinter } from "../../../../../../../store/schedule/usePrinter";

import { Card } from "../../components/Card";
import { DepartmentIcon } from "./DepartmentIcon";


export function Recommended() {
  const customer = usePrinter(st => st.data.customer)
  const pet = usePet(st => st.data.pet)

  if (!customer.recommendations || !customer.recommendations.length) return null

  return (
    <>
      <Card.Header className="mb-2">
        <Card.HeaderTitle>
          Recomendados para {pet.name}
        </Card.HeaderTitle>
      </Card.Header>
      <div className='w-full flex flex-col gap-3 text-black'>
        {customer.recommendations?.map((item) => (
          <div className='border border-black rounded-md p-2 flex gap-3 w-full items-center justify-center' key={item.id}>
            <DepartmentIcon icon={item.icon} />
            <div className='flex w-full flex-col'>
              <p className='font-semibold text-md w-full'>{item.department}</p>
              <p className='text-md w-full'>{item.name}</p>
            </div>
          </div>
        ))}
      </div>
      <Card.Separator />
    </>
  )
}
