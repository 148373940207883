
import FuseClass from "./Fuse";
import SocketClass from "./Socket";
import RecommendationsClass from "./Recommendations";

const BFuse = {};
const BSocket = {}

let BRecommendations = null

export function FuseBuilder(name, list, options) {
  if (!name) {
    throw new Error(
      "Builders/Global - FuseBuilder: name não informado!"
    );
  }
  if (!list) {
    throw new Error(
      "Builders/Global - FuseBuilder: list não informado!"
    );
  }
  if (!!BFuse[name]) {
    return BFuse[name]
  }

  BFuse[name] = new FuseClass(name, list, options);

  return BFuse[name]
}


export function SocketBuilder(name, props) {
  if (!name) {
    throw new Error(
      "Builders/Global - SocketBuilder: name não informado!"
    );
  }

  if (!!BSocket[name]) {
    return BSocket[name]
  }

  BSocket[name] = new SocketClass(props);

  return BSocket[name]
}

export function RecommendationsBuilder(props) {
  if (!props && !BRecommendations) return null

  if (!props && !!BRecommendations) {
    return BRecommendations
  }

  BRecommendations = new RecommendationsClass(props);

  return BRecommendations
}
