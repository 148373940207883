import API from './api'
import {  useHistory} from "react-router-dom";

export const TOKEN_KEY = '@petland'

export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const isAuthenticated = () => getToken() !== null

function clearUser() {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem('STORE')
  localStorage.removeItem('userName')
  localStorage.removeItem('modules')
  localStorage.removeItem('type')
  localStorage.removeItem('VET')
  localStorage.removeItem('theme')
  localStorage.removeItem('documentAssAlert')
  localStorage.removeItem('employee')
  localStorage.removeItem('role')
  localStorage.removeItem('@AgendaPetland-tables')

  window.dispatchEvent(new Event('storage'))
}


export const logout = () => {
  clearUser()
  return true
}

export const login = async (email, password, setLoginClik) => {
  setLoginClik(true)
  clearUser()

  try {
    const { data } = await API.post('user/auth', {
      email,
      password,
    })

    const token = data.token
    const userInfo = data.userInfo || false

    localStorage.setItem("documentAssAlert", false);

    if (!!token) localStorage.setItem(TOKEN_KEY, token);
    if (!!userInfo && userInfo.name) localStorage.setItem("userName", userInfo.name);
    if (!!userInfo && userInfo.store) localStorage.setItem("STORE", userInfo.store);
    if (!!userInfo && userInfo.modules) localStorage.setItem("modules", JSON.stringify(userInfo.modules));
    if (!!userInfo && userInfo.employeeType) localStorage.setItem("VET", userInfo.employeeType);

    if (!!userInfo && userInfo.employeeData?.employeeId) localStorage.setItem('employee', data.userInfo?.employeeData.employeeId)
    if (!!userInfo && !userInfo.employeeData?.employeeId) localStorage.setItem('role', 'master')
    window.dispatchEvent(new Event('storage'))


    setLoginClik(false)
  } catch (err) {

    const error = err.response.data?.error || false
    const friendlyMessage = err.response.data?.friendly_message || 'Erro ao tentar logar no sistema'

    clearUser()
    setLoginClik(false)

    if (!!error) return friendlyMessage
  }

  return true;
}
