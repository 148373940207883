
import { create } from 'zustand'
import { devtools } from "zustand/middleware";
import { produce } from 'immer'

const defaultData = {
  schedule: {},
  customer: {},
  checklist: [],
  services: [],
  newComment: null
}
export const usePrinter = create(devtools((set) => ({
  data: defaultData,
  actions: {
    setSchedule: (schedule) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.schedule = schedule
        }),
      ),
    setNewComment: (comment) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.newComment = comment
        }),
      ),
    setCustomer: (customer) =>
      set((state) =>
        produce(state, (draft) => {
          const currentCustomer = state.data.customer
          draft.data.customer = { ...currentCustomer, ...customer }
        }),
      ),
    setCustomerRecommendations: (recommendations) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.customer.recommendations = recommendations
        }),
      ),
    setChecklist: (checklist) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.checklist = checklist
        }),
      ),
    setServices: (services) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.services = services
        }),
      ),
  },
})))
