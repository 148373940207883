export const CONFIGS = {
  draMei: {
    department: "Clínica Veterinária",
    links: {
      checkIn: "clinica/check-in/order",
      newSchedule: "/clinica/agendamento",
    },
  },
  petland: {
    department: "Banho e Tosa",
    links: {
      checkIn: "/banho-tosa/check-in/order",
      newSchedule: "/banho-tosa/agendamento",
    },
  },
};

export const CALENDAR = {
  public: {
    collumnSize: 400,
    time: {
      gap: 10 * 60,
      displayGap: 1 * 60 * 60,
    },
  },
  status: {
    BLOQUEIO: {
      key: "BLOQUEIO",
      code: "blocked",
      name: "Bloqueio temporário",
    },

    "PRE-AGENDADO": {
      key: "PRE-AGENDADO",
      code: "preScheduled",
      name: "Pré Agendado",
      url: "",
    },
    "VACINA PROGRAMADA": {
      key: "VACINA PROGRAMADA",
      code: "scheduledVaccine",
      name: "Finalizado",
      url: "",
    },
    draMei: {
      GHOST: {
        key: "GHOST",
        code: "gostScheduled",
        name: "gost",
        url: "",
      },
      AGENDADO: {
        // thecashprincess
        key: "AGENDADO",
        code: "scheduled",
        name: "Agendado",
        url: "appointments/reset-status/{ID}",
      },
      "PRE-AGENDADO": {
        key: "PRE-AGENDADO",
        code: "preSchedule",
        name: "Pré Agendado",
        url: "",
      },
      "AGUARDANDO ATENDIMENTO": {
        key: "AGUARDANDO ATENDIMENTO",
        code: "waitingStart",
        name: "Aguardando Atendimento",
        url: "/dra-mei/appointment/{ID}/check-in",
      },
      "EM ATENDIMENTO": {
        key: "EM ATENDIMENTO",
        code: "checkIn",
        name: "Aguardando Atendimento",
        url: "appointments/reset-status/{ID}",
      },
      FINALIZADO: {
        key: "FINALIZADO",
        code: "finished",
        name: "Finalizado",
        url: "service-order/appointment/{ID}/check-in",
      },
      "ORÇAMENTO ENVIADO": {
        key: "ORÇAMENTO ENVIADO",
        code: "checkout",
        name: "Orçamento Enviado",
        url: "service-order/appointment/{ID}/send-to-vo",
      },
    },
    petland: {
      AGENDADO: {
        key: "AGENDADO",
        code: "scheduled",
        name: "Agendado",
        url: "appointments/reset-status/{ID}",
      },
      FINALIZADO: {
        key: "FINALIZADO",
        code: "checkIn",
        name: "Check-in",
        url: "service-order/appointment/{ID}/check-in",
      },
      "ORÇAMENTO ENVIADO": {
        key: "ORÇAMENTO ENVIADO",
        code: "checkout",
        name: "Finalizado",
        url: "service-order/appointment/{ID}/send-to-vo",
      },
    },
  },
  ItemTypes: {
    BUBBLE: "bubble",
  },

  sizes: [
    {
      name: "err",
      range: [0, -999999999],
    },
    {
      name: "sm",
      range: [0, 55.9],
    },
    {
      name: "md",
      range: [56, 79.9],
    },
    {
      name: "lg",
      range: [80],
    },
  ],
};

export const SCHEDULING_DEPARTMENT_STATUS_COLOR = {
  veterinary: {
    newSchedule: "#7092D2",
    scheduled: "#00A1A4",
    waitingStart: "#E3A74B",
    checkIn: "#C64D21",
    finished: "#34A000",
    checkout: "#59602C",
  },
  petShop: {
    newSchedule: "#F97B18",
    scheduled: "#00A1A4",
    waitingStart: "#E3A74B",
    checkIn: "#C64D21",
    finished: "#34A000",
    checkout: "#59602C",
  },
}

export const SCHEDULING_DEPARTMENT_STATUS_CODE = {
  veterinary: {
    AGENDADO: "scheduled",
    "PRE-AGENDADO": "preSchedule",
    "AGUARDANDO ATENDIMENTO": "waitingStart",
    "EM ATENDIMENTO": "checkIn",
    FINALIZADO: "finished",
    "ORÇAMENTO ENVIADO": "checkout",
  },
  petShop: {
    AGENDADO: "scheduled",
    FINALIZADO: "checkIn",
    "ORÇAMENTO ENVIADO": "checkout",
  }
}


export const SEND_SCHEDULE_STATUS = ["scheduled", "waitingStart", "checkIn", "finished", "checkout"]
export const SEND_SCHEDULE_STATUS_MESSAGEN = {
  scheduled: 'sendScheduledSchedulingSummary',
  waitingStart: 'sendScheduledSchedulingSummary',
  checkIn: 'sendScheduledSchedulingSummary',
  finished: 'sendScheduledSchedulingSummary',
  checkout: 'sendScheduledSchedulingSummary',
}

export const PROTOCOL = {
  status: {
    finalizado: {
      name: "Finalizado",
      value: "finished",
    },
    expired: {
      name: "Vencida",
      value: "expired",
    },
    scheduled: {
      name: "Programada",
      value: "scheduled",
    },
    interrompido: {
      name: "Interrompido",
      value: "interrupted",
    },
  },
};

export const VACCINE = {
  status: {
    aplicada: "applied",
    vencida: "expired",
    "vacina programada": "scheduled",
    "em andamento": "scheduled",
    agendado: "scheduled",
  },
};

export const RANKING = {
  pagination: {
    store: {
      page: 0,
      perPage: 10,
    },
    global: {
      page: 0,
      perPage: 10,
    },
  },
};

export const PAGINATION = {
  "*": {
    show: 5,
    perPage: 10,
    currentPage: 0,
  },
  "veterinary-report-vaccinations-table-pagination": {
    show: 5,
    perPage: 10,
    currentPage: 0,
  },
};

export const TIMES = {
  second: 1000,
  minute: 60 * 1000,
  hour: 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  week: 7 * 24 * 60 * 60 * 1000,
  month: 30 * 24 * 60 * 60 * 1000,
  year: 12 * 30 * 24 * 60 * 60 * 1000,
};

export const WHATSAPP_SEND_MESSAGE_BASE =
  "https://web.whatsapp.com/send?phone=55[PHONE_NUMBER]&text=[MESSAGE]";

export const REPORTS_FORCE_VALUES = ["cpf"];

export const BATH_TEXT = ["Banho", "Banhos"]

export const PET_SPECIES = {
  dog: ["Cão", "Cão", "Cães"],
  cat: ["Gato", "Gato", "Gatos"],
};

export const PET_SPECIES_NAME = {
  dog: ["Canina", "Caninas"],
  cat: ["Felina", "Felinas"],
};

export const REPORTS_CONFIG = {
  "subscriptions-invoices-report": {
    name: "subscription-invoices",
    format: "xlsx",
    method: "POST",
    uri: "subscriptions/store/export",
  },
  "subscriptions-subscribers-report": {
    name: "subscription-customers",
    format: "xlsx",
    method: "POST",
    uri: "subscriptions/store/export",
  },
  "reports-royalty-statement-report": {
    name: "subscription-customers",
    format: "xlsx",
    method: "POST",
    uri: "#",
  },
};

export const ARTICLES = {
  macho: ["o", "do", "ele", "um", "no"],
  male: ["o", "do", "ele", "um", "no"],
  fêmea: ["a", "da", "ele", "uma", "na"],
  female: ["a", "da", "ele", "uma", "na"],
};

export const CASTRATED = {
  "*": ["castrado"],
  male: ["castrado"],
  macho: ["castrado"],
  female: ["castrada"],
  fêmea: ["castrada"],
};

export const OUR = {
  macho: ["nosso", "nossos"],
  male: ["nosso", "nossos"],
  fêmea: ["nossa", "nossas"],
  female: ["nossa", "nossas"],
};

export const BUDDY = {
  macho: ["amigo", "amigão", "amigos", "amigões"],
  male: ["amigo", "amigão", "amigos", "amigões"],
  fêmea: ["amiga", "amigona", "amigas", "amigonas"],
  female: ["amiga", "amigona", "amigas", "amigonas"],
};


export const PET_GENDERS = {
  male: ["macho", "machos"],
  female: ["fêmea", "fêmeas"],
};
export const PET_CASTRATED = {
  "yes": { male: "Castrado", female: "Castrada" },
  "no": { male: "Não Castrado", female: "Não Castrada" },
};

export const ATTRIBUTES = {
  Espécie: ["species", "espécie", "espécies"],
  Porte: ["size", "porte", "portes"],
  Peso: ["weight", "peso", "pesos"],
  Sexo: ["gender", "sexo", "sexos"],
  Status: ["status", "status", "status"],
  Raça: ["breed", "raça", "raças"],
  Pelagem: ["fur", "pelagem", "pelagens"],
  Castrado: ["castrated", ["castrado", "castrada"], ["castrados", "castradas"]],

  Idade: ["age", "idade", "idades"],
  Nascimento: ["birth", "nascimento", "nascimentos"],
  Nome: ["name", "nome", "nomes"],
  Tutor: ["owner", "tutor", "tutores"],
  TutorCpf: ["ownerCpf", "CPF", "CPFs"],
  TutorPhone: ["ownerPhone", "telefone", "telefone"],
};

export const DISPLAY_TEXTS = {
  month: ["Mês", "Meses"],
};

export const ATTRIBUTES_NAME = {
  Espécie: "specie",
  Porte: "size",
  Peso: "weight",
  Sexo: "gender",
  Status: "status",
  Raça: "breed",
  Pelagem: "fur",
  Castrado: "castrated",
  Idade: "age",
  Nascimento: "birth",
  Nome: "name",
  Tutor: "owner",
  TutorCpf: "ownerCpf",
  TutorPhone: "ownerPhone",
};

export const WORD_VARIATIONS = {
  application: ["Aplicação", "Aplicações"],
  dose: ["Dose", "Doses"],
};

export const PARTIALS_INSERT = { active: true, inactive: false };

export const BODY_PARSER = {
  specie: {
    dog: "Cão",
    cat: "Gato",
  },
};

export const DEPARTMENT_NAME_VALUE = {
  "Clínica Veterinária": "veterinary",
  "Banho e Tosa": "petShop",
};

export const DEPARTMENT_EMPLOYEE_TYPE_NAME = {
  "veterinary": 'Veterinário(a)',
  "petShop": 'Profissional',
};

export const DEPARTMENT_VALUE_NAME = {
  "veterinary": "Clínica Veterinária",
  "petShop": "Banho e Tosa",
};

export const PET_COMPLETE_REGISTRATION_FIELDS = [
  "petName",
  "birthdate",
  "attributes.Espécie",
  "attributes.Castrado",
  "attributes.Sexo",
  "attributes.Raça",
  "attributes.Peso",
  "customer.cpf",
  "customer.customerDefaultAddress",
  "customer.email",
  "customer.fullName",
  "customer.phoneNumber",
]

export const FILE_EXTENSIONS_TYPE = {
  xls: ["xlsx", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlt", "xml", "xlam", "xla", "xlw", "xlr", "csv", 'msexel'],
  doc: ["doc", "docm", "docx", "dot", "dotm", "dotx", "mht", "mhtml", "Odt", "Rtf", "txt", "Wps", "Xps", 'msword'],
  img: ["image", "jpeg", "jpg", "png", "gif", "bmp", "tiff", "tif", "webp", "svg"],
  pdf: ["pdf"],
  html: ['html'],
  vaccine: ['vaccine'],
  unidentified: ['.*'],
}

export const USER_TYPE_NAME_VALUE = {
  'Veterinário': 'veterinary',
  'Gerente': 'manager',
  'Tosador 1': 'groomer',
  'Tosador 2': 'groomer',
  'Groomer': 'groomer',
  'Recepcionista': 'receptionist',
  'Banhista': 'bather',
  'Motorista': 'driver',
}

export const USER_TYPE_NAME_LEVEL = {
  'Tosador 1': 1,
  'Tosador 2': 2,
  'Groomer': 3,
}

export const SUBSCRIBER_STATUS_COLOR = {
  'Em atraso': '#ff0000',
  'Ativo': '#3fdb61',
  'Cancelado': '#666666',
  'Pago': '#81ff35',
  "Falha": "#ffd700",
  "Pausado": "#f8cd04",
}

export const SUBSCRIBER_CYCLE_STATUS = {
  'Em atraso': 'Em atraso',
  'Ativo': 'Ativo',
  'paid': 'Pago',
  'Cancelado': 'Cancelado',
  "failed": "Falha",
}

export const RECOMMENDATION_DEPARTMENT = {
  "*": '',
  "ALIMENTOS COMPLETOS": 'Alimentos Completos',
  "OUTROS ALIMENTOS": 'outros Alimentos',
  "HIGIENE E LIMPEZA": 'Higiene e Limpeza',
  "MEDICAMENTOS": 'Medicamentos',
  "ACESSÓRIOS": 'Acessórios',
}

export const RECOMMENDATION_DEPARTMENT_ICON = {
  "*": {
    type: 'icon',
    name: 'paw'
  },
  "ALIMENTOS COMPLETOS": {
    type: 'img',
    src: '/img/print/category/food.png'
  },
  "OUTROS ALIMENTOS": {
    type: 'img',
    src: '/img/print/category/snack.png'
  },
  "HIGIENE E LIMPEZA": {
    type: 'img',
    src: '/img/print/category/hygiene.png'
  },
  "MEDICAMENTOS": {
    type: 'img',
    src: '/img/print/category/medicines.png'
  },
  "ACESSÓRIOS": {
    type: 'img',
    src: '/img/print/category/accessories.png'
  },
}
