import API from './api'

export const newCustomer = async (customer = {}) => {
  try {
    const data = await API.post('customers/new', customer)
    return data
  } catch (err) {
    return err?.response
  }
}

export const editCustomer = async (customer = {}, customerId) => {
  try {
    const data = await API.put(`customer/edit/${customerId}`, customer)
    return data
  } catch (err) {
    return err?.response
  }
}

export const getCustomers = async (cpf = '', nome = '', pet = '', tel = '') => {
  try {
    const {
      data: { results = [] },
    } = await API.get(
      `customers/search?${cpf !== '' ? `cpf=${cpf}&` : ''}${
        nome !== '' ? `customerName=${nome}&` : ''
      }${pet !== '' ? `petName=${pet}` : ''}${
        tel !== '' ? `cellphone=${tel}` : ''
      }`,
    )

    return results
  } catch (err) {
    return err?.response?.data?.friendly_message
  }
}

export const getCustomer = async (customerId) => {
  const { data } = await API.get(`customer/${customerId}`)

  return data
}
