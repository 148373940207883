import { Card } from '../components/Card'
import { Cashback } from './Cashback'
import { Recommended } from './Recommended'
import { Header } from '../components/Header'

import { Pet } from './Pet'

export function Client() {
  return (
    <>
      <Header.Customer />
      <Card.Root>
        <Card.Main>
          <Cashback />
          <Recommended />
          <Pet />
        </Card.Main>
      </Card.Root>
    </>
  )
}
