import React from "react";
import { ThemeProvider } from "styled-components";
import Routes from "./pages/routes";
import { useThemes } from "./providers/theme";
import GlobalCSS from "./styles/global";
import "react-indiana-drag-scroll/dist/style.css";
import { getCurrentThemeStyle } from "./services/functions";
import { connectSockets } from "./services/sockets";
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from "./services/react-query";

function App() {
  connectSockets();

  const { theme } = useThemes();
  const themeStyle = getCurrentThemeStyle()


  return (
    <QueryClientProvider client={queryClient}>

      <ThemeProvider theme={themeStyle}>
        <GlobalCSS />
        <Routes />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
