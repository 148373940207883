import React, { useState, useEffect, useContext } from "react";

import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

// Material ui - icones
import AddCircleIcon from "@material-ui/icons/AddCircle";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import CloseIcon from "@material-ui/icons/Close";
import API from "../../../services/api";

// Componentes
import ModalType from "../../atoms/Modal";

// Base Page
import BasePage from "../../../pages/basePage";

// Serviço
import { addOrder, newOrder } from "../../../services/order";
import { decodeUrlParans, getServices } from "../../../services/services";
import { getSellers } from "../../../services/sellers";

import DrawerType from "../../atoms/Drawer";
import TaxiDog from "./TaxiDog";

// Componente Organisms
import Scheduling from "../Scheduling";
import SidebarCustomer from "../SidebarCustomer";

// Componente Molecules
import RepeatAppointment from "../../molecules/RepeatAppointment";
import { TaxiDogProvider } from "../../../hooks/TaxiDogContext";
import { AuthContext } from "../../../providers/auth";

moment.locale("pt-BR");

const useStyles = makeStyles({
  button: {
    color: "#fff",
    fontSize: "15px",
    borderRadius: "7px",
    border: "0",
    backgroundColor: "#25d366",
    marginTop: "20px",
    "&:hover": {
      backgroundColor: "#1dba58",
      border: 0,
      color: "#fff",
    },
  },
  successTitle: {
    color: "#47A59D",
    fontSize: "36px",
    margin: 0,
    textAlign: "center",
  },
  successNumber: {
    color: "#47A59D",
    fontSize: "36px",
    fontWeight: "bold",
    margin: 0,
    textAlign: "center",
  },
  back: {
    color: "#666",
    display: "flex",
    fontSize: "14px",
    justifyContent: "space-around",
    textDecoration: "none",
    margin: "50px 0px 15px 0px",
    "&:hover": {
      color: "#ccc",
    },
  },
  back1: {
    color: "#666",
    display: "flex",
    fontSize: "14px",
    justifyContent: "space-around",
    textDecoration: "none",
    textTransform: "uppercase",
    marginBottom: "15px",
    cursor: "pointer",
    "&:hover": {
      color: "#ccc",
    },
  },
  imageContainer: {
    textAlign: "center",
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#666666",
    marginTop: "15px",
  },
  textField: {
    marginTop: "10px",
  },
  textService: {
    fontSize: "16px",
    color: "#666666",
    padding: "10px 75px",
    textAlign: "center",
  },
});

const CustomButton = withStyles(() => ({
  root: {
    color: "#fff",
    border: "0",
    backgroundColor: "#ccd219",
    marginTop: "20px",
    "&:hover": {
      backgroundColor: "#a0a510",
      border: 0,
      color: "#fff",
    },
  },
}))(Button);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getDate = (date) => {
  const newDate = moment(date).format("YYYY-MM-DD");
  return newDate;
};

const getTime = (date) => {
  const newDate = new Date(date);
  const hour =
    newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours();
  const minutes =
    newDate.getMinutes() < 10
      ? `0${newDate.getMinutes()}`
      : newDate.getMinutes();

  return `${hour}:${minutes}`;
};

function Agendamento({ event, professional }) {
  const classes = useStyles();
  const { data } = useParams();

  if (data) {
    const byUrl = decodeUrlParans(data);
    event = { start: new Date(byUrl.event.start) };
    professional = byUrl.professional;
  }

  const date = getDate(event.start);
  const time = getTime(event.start);

  const { register, control, handleSubmit, watch } = useForm({
    defaultValues: {
      scheduling: [
        {
          professional: "",
          duration: "",
          service: "",
          time: "",
        },
      ],
    },
  });



  const schedulingFields = watch("scheduling");
  const { modules } = useContext(AuthContext);

  const [availableTimes, setAvailableTimes] = useState([]);
  const [services, setServices] = useState([]);
  const [servicesNow, setServicesNow] = useState([]);
  const [order, setOrder] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [errorCustomer, setErrorCustomer] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [errorSeller, setErrorSeller] = useState(false);
  const [seller, setSeller] = useState("");
  const [payload, setPayload] = React.useState("");

  // State Cupom
  const [activeCupom, setActiveCupom] = React.useState(false);
  const [taxiDogData, setTaxiDogData] = React.useState({});
  const [valueCupom, setValueCupom] = React.useState(false);
  const [priceCupom, setPriceCupom] = React.useState(false);
  const [errCupom, setErrCupom] = React.useState(false);
  const [successCupom, setSuccessCupom] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openTaxidogDrawer, setOpenTaxidogDrawer] = useState(false);

  // FINAL PRICE
  const [finalPrice, setFinalPrice] = useState(0);

  const [stateButtom, setStateButtom] = React.useState(false);

  // state array horas
  const [schedulings, setSchedulings] = useState([
    {
      id: Math.random(),
      service: null,
      time,
      duration: null,
      start: event.start,
    },
  ]);

  // Modal
  const [openModal, setOpenModal] = React.useState(null);
  const [error, setError] = React.useState(null);

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleAddService = (valTime) => {
    setSchedulings([
      ...schedulings,
      {
        id: Math.random(),
        service: null,
        time: getTime(valTime),
        duration: null,
        start: valTime,
      },
    ]);
  };

  const handleSellers = async () => {
    const result = await getSellers();
    setSellers(result);
  };

  const handleSeller = (event, value) => {
    setSeller(value?.id);
  };

  const onSubmit = async ({ scheduling = [], description }) => {
    setStateButtom(true);

    if (!customer) {
      setErrorCustomer(true);
      setStateButtom(false);
      return;
    }

    if (!seller) {
      setErrorSeller(true);
      setStateButtom(false);
      return;
    }

    const serviceOrders = scheduling?.map((item, i) => {
      return {
        serviceId: item.service,
        employeeId: professional.resourceId,
        startsAt: schedulings[i].time,
        durationInMinutes: parseInt(schedulings[i].duration, 10),
      };
    });

    const { data } = await newOrder(
      customer,
      date,
      serviceOrders,
      seller,
      payload.recurring,
      description,
      valueCupom,
      setOpenModal,
      setError,
      setStateButtom,
      taxiDogData
    );
    setOrder(data);
  };

  const filterPrice = schedulingFields.map(({ service }) => service);
  const filterPriceCupom = services.filter(({ id }) =>
    filterPrice.includes(id)
  );

  const valueIdService = filterPriceCupom?.map((item) => item.id);

  const handleAddOrder = async (serviceOrders = []) => {
    const {
      data: { availableTimes: times = [] },
    } = await addOrder(date, professional.resourceId, serviceOrders);
    setAvailableTimes(times);
  };

  async function getEvents() {
    // const today = moment().format("YYYY-MM-DD");
  }

  async function handleServices() {
    const data = await getServices(customer.pet.id);
    setServices(data);
    setServicesNow(data);
  }
  function filterServices() {
    const arrayFields = schedulingFields.map((item) => item.service);
    const res = services.filter((item) => !arrayFields.includes(item.id));

    setServicesNow(res);
  }

  useEffect(() => {
    if (customer) {
      handleServices();
      filterServices();
    }
  }, [customer]);

  useEffect(() => {
    getEvents();
    handleAddOrder();
    window.scrollTo(0, 0);
  }, []);

  const handleCupom = async () => {
    setLoading(true);
    try {
      const result = await API.post("coupons/apply", {
        appointmentId: "",
        channel: "LOJA",
        code: valueCupom,
        petId: customer.pet.id,
        customerId: customer?.id,
        servicesId: valueIdService,
        appointmentDate: moment(event.start).format("YYYY-MM-DD"),
      });

      if (result?.status) {
        setSuccessCupom(true);
        setLoading(false);
      }

      setErrCupom(false);
      setPriceCupom(result?.data?.totalPriceWithDiscount);
    } catch (err) {
      setErrCupom(err?.response?.data?.friendly_message);
      setLoading(false);
    }
  };

  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      if (valueCupom) handleCupom();
      target.preventDefault();
    }
  };

  const cupom = watch("cupom");

  useEffect(() => {
    if (cupom) setValueCupom(cupom);
  }, [cupom]);

  useEffect(() => {
    if (valueCupom) handleCupom();
  }, [finalPrice]);

  useEffect(() => {
    filterServices();
  }, [schedulings]);

  return (
    <BasePage
      pageTitle={`Novo Agendamento - ${moment(event?.start).format(
        "DD/MM/YYYY"
      )}`}
    >
      <TaxiDogProvider>
        <ModalType
          open={openModal && true}
          close={closeModal}
          titleDescription={openModal}
          err={error}
          verification={false}
        />
        <DrawerType
          open={openTaxidogDrawer}
          setDrowerOpen={setOpenTaxidogDrawer}
          titleHeader="ADICIONAR TAXI DOG"
          anchor="right"
          clear
          content={
            <TaxiDog
              customer={customer}
              setTaxiDogData={setTaxiDogData}
              close={() => {
                setOpenTaxidogDrawer(false);
              }}
            />
          }
        />

        <Grid
          container
          spacing={3}
          style={{ background: "#ffffff", borderRadius: "7px" }}
        >
          <Grid item xs={4} style={{ borderRight: "2px solid #f6f6f6" }}>
            <SidebarCustomer
              order={order}
              loading={loading}
              customer={customer}
              setCustomer={setCustomer}
              priceCupom={priceCupom}
              schedulings={schedulingFields}
              services={services}
              finalPrice={finalPrice}
              setFinalPrice={setFinalPrice}
            />
            <Grid item xs={12}>
              <Divider variant="fullWidth" style={{ marginTop: "20px" }} />
            </Grid>

            <Grid item xs={12} className={classes.subtitle}>
              Vendedor
            </Grid>

            <Autocomplete
              disableClearable
              required
              options={sellers}
              loading
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendedor"
                  placeholder="Digite o nome do vendedor"
                  margin="normal"
                  variant="outlined"
                  onChange={handleSellers}
                />
              )}
              onChange={handleSeller}
            />
          </Grid>
          <Grid item xs={8}>
            {order ? (
              <>
                <Grid item xs={12}>
                  <p className={classes.successTitle}>Serviço Agendado</p>
                  <p className={classes.successNumber}>
                    {order?.appointmentOrderId}
                  </p>
                  <p className={classes.textService}>
                    Serviço agendado para o dia{" "}
                    {moment(order?.appointmentDate).format("DD/MM")} às{" "}
                    {order?.appointmentStartsAt}, total do serviço{" "}
                    {order?.totalPrice.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </p>
                </Grid>

                <Grid item xs={12} className={classes.imageContainer}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: `https://wa.me/55${order?.customerCellphone
                        .replace(/\D/g, "")
                        .trim()}?text=Ol%C3%A1%20${order?.customerName
                        }%20segue%20o%20resumo%20do%20seu%20agendamento%0A%0A*Pet:*%20${order?.petName
                        }%0A*Valor%20do%20agendamento:*%20R$%20${order?.totalPrice
                        }%0A*Local:*%20${order?.storeName}%0A*Endere%C3%A7o:*%20${order?.storeAddress
                        }%0A*Data:*%20${order?.appointmentDate
                        }%0A*Hor%C3%A1rio:*%20${order?.appointmentStartsAt}`,
                    }}
                    target="_blank"
                  >
                    <Button
                      endIcon={<WhatsAppIcon />}
                      type="submit"
                      color="secondary"
                      size="large"
                      className={classes.button}
                    >
                      Enviar por WhatsApp
                    </Button>
                  </Link>
                </Grid>

                <Grid item xs={12}>
                  <Link to="/agenda" className={classes.back}>
                    VOLTAR PARA AGENDA
                  </Link>
                </Grid>

                <Grid item xs={12}>
                  <Link
                    to={`/agenda?date=${order?.appointmentDate}`}
                    className={classes.back1}
                  >
                    Voltar para agenda no dia
                  </Link>
                </Grid>

                <Grid item xs={12}>
                  <a href={window.location.pathname} className={classes.back1}>
                    Fazer um novo agendamento para o mesmo profissional
                  </a>
                </Grid>
              </>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <h3>Adicione o Serviço</h3>
                  </Grid>
                  <Grid item xs={4}>
                    <RepeatAppointment
                      payload={payload}
                      setPayload={setPayload}
                      setActiveCupom={setActiveCupom}
                      activeCupom={activeCupom}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  {schedulings.map(
                    (item, index) =>
                      item && (
                        <Scheduling
                          key={item.id}
                          filterServices={filterServices}
                          index={index}
                          serviceId={item.id}
                          serviceDuration={item.duration}
                          time={item.time}
                          services={item.service ? services : servicesNow}
                          professional={professional}
                          availableTimes={availableTimes}
                          setSchedulings={setSchedulings}
                          schedulings={schedulings}
                          control={control}
                          register={register}
                          item={item}
                          customer={customer}
                          segment="banhoetosa"
                        />
                      )
                  )}

                  <Grid item xs={12}>
                    <Button
                      color="secondary"
                      startIcon={<AddCircleIcon />}
                      onClick={() =>
                        handleAddService(
                          moment(schedulings[schedulings.length - 1].start).add(
                            schedulings[schedulings.length - 1].duration,
                            "minute"
                          )
                        )
                      }
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                      disabled={!customer}
                    >
                      ADICIONAR MAIS SERVIÇOS
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      startIcon={<ConfirmationNumberIcon />}
                      onClick={() => setActiveCupom(true)}
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                      disabled={!customer}
                    >
                      Cupom
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      startIcon={<DirectionsCar />}
                      onClick={() => setOpenTaxidogDrawer(true)}
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#EFD90F",
                      }}
                      disabled={!customer}
                    >
                      {!taxiDogData.driverId
                        ? "Adicionar Taxi Dog"
                        : "Editar Taxi Dog"}
                    </Button>
                    {taxiDogData.driverId && (
                      <>
                        <p style={{ margin: "0 0 0 6px" }}>
                          <strong>Motorista: </strong> {taxiDogData.driverName}
                        </p>
                        <p style={{ margin: "0 0 0 6px" }}>
                          <strong>Valor: </strong>
                          {taxiDogData?.total === 0 && (
                            <span
                              style={{
                                background: "#f00",
                                borderRadius: "7px",
                                padding: "0px 10px",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              Pacote
                            </span>
                          )}
                          {taxiDogData?.total > 0 &&
                            Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(taxiDogData?.total)}
                        </p>
                        <p style={{ margin: "0 0 0 6px" }}>
                          <strong>Receber: </strong>{" "}
                          {taxiDogData.driverPickupType}
                        </p>
                        <p style={{ margin: "0 0 0 6px" }}>
                          <strong>Tipo: </strong> {taxiDogData.taxiDogType}
                        </p>
                        <p
                          onClick={() => {
                            setTaxiDogData({});
                          }}
                          style={{
                            margin: "0 0 0 6px",
                            color: "#F00E0E",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          REMOVER TAXI DOG
                        </p>
                      </>
                    )}
                    {customer?.cpf && !customer.cpf.includes('*') && !!modules?.gaida && !!modules?.gaida.schedule && <iframe src={`https://auth.gaida.tech/suggestion/${customer?.cpf}?expand=true`} className="w-full h-[300px]"></iframe>}
                  </Grid>

                  {activeCupom && (
                    <>
                      <Grid item xs={7}>
                        <TextField
                          id="cupom"
                          type="text"
                          label="Cupom"
                          name="cupom"
                          variant="outlined"
                          fullWidth
                          placeholder="Insira aqui seu cupom de desconto..."
                          inputRef={register}
                          onKeyPress={handleKeyPress}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: loading && <CircularProgress />,
                          }}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#CCD219", color: "#fff" }}
                          onClick={() => handleCupom()}
                        >
                          APLICAR
                        </Button>
                      </Grid>

                      <Grid item xs={1}>
                        <IconButton
                          aria-label="close"
                          onClick={() => {
                            setActiveCupom(false);
                            setPriceCupom(false);
                            setValueCupom(false);
                            setErrCupom(false);
                          }}
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <CloseIcon
                            style={{ color: "#f33838", fontSize: "24px" }}
                          />
                        </IconButton>
                      </Grid>
                    </>
                  )}

                  {errCupom && (
                    <Grid item xs={7}>
                      <Alert severity="error">{errCupom}</Alert>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <TextField
                        label="Observação"
                        inputRef={register}
                        id="description"
                        name="description"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomButton
                      fullWidth
                      color="secondary"
                      size="large"
                      type="submit"
                      justify="center"
                      alignItems="center"
                      className={classes.button}
                      disabled={stateButtom}
                    >
                      Salvar
                    </CustomButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Grid>
        </Grid>
        <Snackbar
          open={errorCustomer || errorSeller}
          autoHideDuration={3000}
          onClose={() => {
            setErrorCustomer(false);
            setErrorSeller(false);
          }}
        >
          <Alert severity="warning">
            {errorCustomer
              ? "Por favor, selecione o cliente antes de continuar!"
              : "Por favor, selecione o vendedor antes de continuar!"}
          </Alert>
        </Snackbar>

        <Snackbar
          open={successCupom}
          autoHideDuration={3000}
          onClose={() => {
            setSuccessCupom(false);
          }}
        >
          <Alert severity="success">Cupom inserido com sucesso!</Alert>
        </Snackbar>
      </TaxiDogProvider>
    </BasePage>
  );
}

export default Agendamento;
