import _ from "lodash";

import MerchantClass from "./Merchant";
import MedicineClass from "./Medicine";
import PetClass from "./Pet";
import UserClass from "./User";

let CUser = null;
let CMerchant = null;

const CPet = {};
const CMedicines = {};

export function MerchantController() {
  if (CMerchant) {
    return CMerchant;
  }
  CMerchant = new MerchantClass();

  return CMerchant;
}

export function UserController() {
  if (CUser) return CUser;

  CUser = new UserClass();

  return CUser;
}

export function PetController(petId) {
  const totalPets = _.size(CPet)
  if (!petId && !totalPets) {
    throw new Error(
      "Controllers/Global - PetController: ID não informado!"
    );
  }

  if (!petId && totalPets > 1) {
    throw new Error(
      "Controllers/Global - PetController: ID não informado!"
    );
  }

  if (!petId && totalPets === 1) {
    return _.values(CPet)[0];
  }

  if (!!CPet[petId]) {
    return CPet[petId];
  }

  CPet[petId] = new PetClass(petId);

  return CPet[petId];
}



export function MedicineController(id, props) {
  if (!id) {
    throw new Error(
      "Controllers/Global - MedicineController: ID não informado!"
    );
  }
  if (!!CMedicines[id]) {
    return CMedicines[id]
  }

  CMedicines[id] = new MedicineClass(id, props);

  return CMedicines[id]
}
