import axios from 'axios'
import { getToken, logout } from './auth'

const api = axios.create({
  baseURL: process.env.REACT_APP_HUB_API_URL,
})

export const gaida = axios.create({
  baseURL: "https://copilot.gaida.tech/api",
  headers: {
    'x-api-key': '3d703337-e410-4fd3-bb7c-dee5ef15483f',
  },
})

api.interceptors.request.use(async (config) => {
  const token = getToken()

  config.headers['unic-request'] = `${new Date()} - ${Math.random()}`

  if (token) config.headers.Authorization = `${token}`

  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status === 403 &&
      error?.response?.data?.friendly_message === 'Token expirado.'
    ) {
      logout()
      window.location = '/'
    } else {
      return Promise.reject(error)
    }
  },
)

export default api
